import { Component, Vue } from 'vue-property-decorator';

import SellSheetsItem from '@/common/interfaces/SellSheetsItem';

const SellSheetsBlockProps = Vue.extend({
  props: {
    item: Object as () => SellSheetsItem,
  },
});
@Component
export default class SellSheetsBlock extends SellSheetsBlockProps {}
