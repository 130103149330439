import { Component, Vue } from 'vue-property-decorator';
import Info from '@/assets/icons/info.svg?inline';
import { stripHtml } from '@/utils/stringUtils';

const DurationTooltipProps = Vue.extend({
  props: {
    text: String,
    noLinks: Boolean,
  },
});
@Component({
  components: {
    Info,
  },
})
export default class DurationTooltip extends DurationTooltipProps {
  htmlId = '';
  created() {
    this.htmlId = Math.floor(Math.random() * Date.now()).toString(16);
  }
  get tooltip() {
    return this.noLinks ? this.text.replace(/<a\b[^>]*>(.*?)<\/a>\.?/i, '') : this.text
  }
  get tooltipLabel() {
    return this.noLinks ? stripHtml(this.text.replace(/<a\b[^>]*>(.*?)<\/a>\.?/i, '')) : stripHtml(this.text)
  }
}
