import { Component, Vue } from 'vue-property-decorator';
import TitleLinkColumnsItem from '@/common/interfaces/TitleLinkColumnsItem';

const TitleLinkColumnsProps = Vue.extend({
  props: {
    item: Object as () => TitleLinkColumnsItem[],
  },
});
@Component({
  components: {},
})
export default class TitleLinkColumns extends TitleLinkColumnsProps {}
