import { Component, Vue, Prop } from 'vue-property-decorator';
import HygieneTableItem from '@/common/interfaces/HygieneTableItem';

@Component({
  components: {},
})
export default class HygieneTable extends Vue {
  @Prop({ type: Object })
  readonly item!: HygieneTableItem;

  @Prop({ type: Boolean, default: false })
  readonly highlightFooter!: boolean;

  get titles() {
    return this.item.Titles || [this.item.LabelTitle, this.item.TextTitle];
  }

  get items() {
    return this.item.CustomItems || this.item.Items.map((i) => [i.Label, i.Text]);
  }
}
