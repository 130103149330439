import { Component, Vue } from 'vue-property-decorator';

import TextGeneric from '@/components/TextGeneric/TextGeneric.vue';
import CtaButton from '@/components/CtaButton/CtaButton.vue';
import ContentItem from '@/common/interfaces/ContentItem';
import { ContentType } from '@/common/interfaces/ContentItem';
import SellSheetsBlock from '@/components/SellSheetsBlock/SellSheetsBlock.vue';
import HygieneTableBlock from '@/components/HygieneTable/HygieneTable.vue';
import TitleLinkColumnsBlock from '@/components/TitleLinkColumns/TitleLinkColumns.vue';

const blockType: { [key in ContentType]: string } = {
  ContentAccordionButtonBlock: 'CtaButton',
  ContentAccordionPhoneBlock: 'CtaButton',
  ContentTextGenericBlock: 'TextGeneric',
  Accordion: 'Accordion',
  ContentAccordionBlock: 'Accordion',
  SellSheetsItem: 'SellSheetsBlock',
  HygieneTableBlock: 'HygieneTableBlock',
  TitleLinkColumnsBlock: 'TitleLinkColumnsBlock',
};

const componentProps: {
  [key in ContentType]: (item: ContentItem) => any;
} = {
  ContentAccordionButtonBlock: (item) => ({ item: item.Item }),
  ContentAccordionPhoneBlock: (item) => ({ item: item.Item }),
  ContentTextGenericBlock: (item) => ({ item: item.Item }),
  Accordion: (item) => ({ items: [item.Item] }),
  ContentAccordionBlock: (item) => ({ items: item.Item }),
  SellSheetsItem: (item) => ({ item: item.Item }),
  HygieneTableBlock: (item) => ({ item: item.Item }),
  TitleLinkColumnsBlock: (item) => ({ item: item.Item }),
};

const GenericComponentProps = Vue.extend({
  props: { item: Object as () => ContentItem },
});

@Component({
  components: {
    TextGeneric,
    CtaButton,
    Accordion: () => import('@/components/Accordion/Accordion.vue'),
    SellSheetsBlock,
    HygieneTableBlock,
    TitleLinkColumnsBlock,
  },
})
export default class GenericComponent extends GenericComponentProps {
  getComponentName(item: ContentItem) {
    return blockType[item.Type];
  }

  getInnerComponentProps(item: ContentItem) {
    return componentProps[item.Type](item);
  }
}
