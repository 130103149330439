import Vue from 'vue';
import { Component, Ref, Watch, Prop } from 'vue-property-decorator';
import { ItineraryCard } from './ItineraryCard.types';
import BadgeLabel from '@/components/BadgeLabel/BadgeLabel.vue';
import ButtonClose from '@/components/ButtonClose/ButtonClose.vue';
import ButtonWishlist from '@/components/ButtonWishlist/ButtonWishlist.vue';
import IconCalendar from '@/assets/icons/calendar.svg?inline';
import IconPinFilled from '@/assets/icons/pin-filled.svg?inline';
import IconPlus from '@/assets/icons/plus.svg?inline';
import ResponsiveImageRatio from '@/components/ResponsiveImageRatio/ResponsiveImageRatio.vue';
import ResponsiveImage from '@/components/ResponsiveImage/ResponsiveImage.vue';
import { ImageWidths, ImageSizes, ImageSize, BREAKPOINTS } from '@/common/interfaces/SizeBreakpoints';
import Accordion from '@/components/Accordion/Accordion.vue';
import Chevron from '@/assets/icons/chevron.svg?inline';

import { mobileViewPort, throttled } from '@/utils/commonUtils';

import OverlayMixin from '@/mixins/OverlayMixin';
import { mixins } from 'vue-class-component';
import { SegmentElement } from '@/plugins/utm/SegmentElement';
import DurationTooltip from '@/components/DurationTooltip/DurationTooltip.vue';

@Component({
  props: { card: Object as () => ItineraryCard },
  components: {
    BadgeLabel,
    ButtonClose,
    ButtonWishlist,
    IconCalendar,
    IconPinFilled,
    IconPlus,
    ResponsiveImageRatio,
    ResponsiveImage,
    Accordion,
    Chevron,
    DurationTooltip,
  },
})
export default class ItineraryCardComponent extends mixins(OverlayMixin) {
  readonly card!: ItineraryCard;
  segmentProps = {};

  @Prop()
  readonly resetCardState!: boolean;

  isHovered: boolean = false;
  isActive: boolean = false;
  sideActive: boolean = false;
  cardHeight: number = 0;
  backContentOverlay = false;
  accordionOverlay = false;
  hasOffers: boolean = this.card.SpecialOffers != undefined && this.card.SpecialOffers.length > 0;
  windowSM = 720;

  isOpen = false;

  @Watch('isOpen')
  function(isOpen: boolean) {
    if (window.outerWidth <= this.windowSM && window.outerWidth >= BREAKPOINTS.sm) {
      this.openCloseOverlay(isOpen, (this.backContent, this.accordionWrapper));
    }
  }

  imageWidths: ImageWidths = {
    def: 330,
    sizes: {
      sm: 460,
      md: 750
    },
  };

  imageSizes: ImageSizes = {
    def: new ImageSize([375, 812]),
    sizes: {
      sm: new ImageSize([768, 1024]),
      md: new ImageSize([991, 800]),
    },
  };

  @Ref('accordion-wrapper')
  readonly accordionWrapper!: HTMLElement;

  @Ref('back-content')
  readonly backContent!: HTMLElement;

  toggleActive(): void {
    this.isActive = !this.isActive;
    this.checkBottom();
    if (!this.isOpen) {
      this.onModuleInteraction('Highlights', 'expansion')
    } else {
      this.onModuleInteraction('Highlights', 'closure')
    }
  }

  toggleSide(): void {
    this.sideActive = !this.sideActive;
    this.checkBottom();
    if (!this.isOpen) {
      this.onModuleInteraction('Special Offers', 'expansion')
    } else {
      this.onModuleInteraction('Highlights', 'closure')
    }
  }

  uncheckAccordion() {
    var checkBoxes = document.querySelectorAll('input[type=checkbox]');
    for (var i = 0; i < checkBoxes.length; i++) {
      var checkedBox = checkBoxes[i] as HTMLInputElement;
      checkedBox.checked = false;
    }
  }

  checkBottom() {
    if (
      this.backContent.scrollTop + this.backContent.clientHeight ==
      this.backContent.scrollHeight
    ) {
      this.backContentOverlay = true;
    } else {
      this.backContentOverlay = false;
    }

    if (
      this.accordionWrapper.children[0].scrollTop +
        this.accordionWrapper.children[0].clientHeight ==
      this.accordionWrapper.children[0].scrollHeight
    ) {
      this.accordionOverlay = true;
    } else {
      this.accordionOverlay = false;
    }
    this.backContent.scrollHeight > this.backContent.clientHeight
      ? (this.backContentOverlay = true)
      : (this.backContentOverlay = false);
  }

  mobileViewPort() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  onModuleInteraction(text: string, interaction_type: string) {
    SegmentElement.sendEvent('moduleInteraction', {
      ...this.segmentProps,
      interaction_type,
      text,
    })
  }

  mounted(): void {
    const module = this.$el.closest('*[contentlinkid]') as HTMLElement;
    const parent = this.$el.closest('.filterable-grid__grid-item') as HTMLElement;
    this.segmentProps = {
      ...(parent && {...JSON.parse(parent.dataset.segment)}),
      module_type_id: module.getAttribute('data-segmentid'),
      module_type_name: module.getAttribute('type')
    };

    if (this.backContent && this.backContent.scrollHeight >= this.backContent.clientHeight) {
      this.backContent.addEventListener('scroll', this.checkBottom);
    } else {
      this.backContentOverlay = true;
    }

    if (this.card.SpecialOffers && this.accordionWrapper) {
      if (
        this.accordionWrapper.children[0].scrollHeight >=
        this.accordionWrapper.children[0].clientHeight
      ) {
        this.accordionWrapper.children[0].addEventListener('scroll', this.checkBottom);
      } else {
        this.accordionOverlay = true;
      }
    }

    mobileViewPort();

    window.addEventListener('resize', throttled(100, this.mobileViewPort));
  }

  @Watch('resetCardState')
  resetCard() {
    this.isActive = false;
    this.sideActive = false;
  }
}
