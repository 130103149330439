import { render, staticRenderFns } from "./ItineraryCard.vue?vue&type=template&id=10b04783&"
import script from "./ItineraryCard.ts?vue&type=script&lang=ts&"
export * from "./ItineraryCard.ts?vue&type=script&lang=ts&"
import style0 from "./ItineraryCard.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports