import { Component, Vue } from 'vue-property-decorator';
import ArrowSmall from '@/assets/icons/arrow-small.svg?inline';
import GenericComponent from '@/components/GenericComponent/GenericComponent.vue';
import AccordionItem from '@/common/interfaces/AccordionItem';

const AccordionProps = Vue.extend({
  props: {
    items: Array as () => AccordionItem[],
  },
});
@Component({
  components: {
    GenericComponent,
    ArrowSmall,
  },
})
export default class Accordion extends AccordionProps {
  currentItemIndex: number = -1;

  onQuestionClick(index: number, text: string) {
    this.$emit('moduleInteraction', text);
    this.currentItemIndex = this.currentItemIndex === index ? -1 : index;
  }
}
